<script setup lang="ts">
import { showNewReturnFeature } from 'internal/beta-features'

import { LoginMigration } from '@fond-of/affenzahn-ui'
import { localizeUrl } from '@fond-of/locale'

const afterLogin = () => {
  globalThis.location.href = localizeUrl(
    showNewReturnFeature() ? '/account/orders' : '/p/orders'
  )
}
</script>

<template>
  <LoginMigration
    source="navigation"
    @after-login="afterLogin"
  />
</template>
